@import "~bootstrap/scss/bootstrap";

html,
body,
#root {
  height: 100%;
  width: 100%;
}

.app-content {
  margin: auto;
  max-width: 997px;
  padding: 1rem;
}
.device-iframe {
  height: 100%;
  width: 100%;
}

.inline {
  display: inline;
}